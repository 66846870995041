/*global google*/
import { getMapsHelper } from './util/mapsHelper.js';

const homeMap = {
	
	theMap: {
		ele: document.querySelector('#home_map'),
		instance: null
	},
	init: async function() {
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '.fac_info_item',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<a href="${el.getAttribute('link')}" class="map_pin">
						<span>${el.getAttribute('count')}</span>
						<img src="/dist/images/icons/map_pin.svg" alt="" />
					</a>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(14);
		});
	}
};


export const initHomeMap = () => homeMap.init();