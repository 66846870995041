import './polyfills';

import { initMobileNav } from './header';
import { initContactMap, contactSubmit } from './contact.js';
import { initHomeMap } from './home.js';
import { initFacilityTop, initFacCaro, initFacMap, initJumpLinks, initFilters } from './facility';
import { initReviewsCaro } from './reviews';

initMobileNav();

if (document.getElementById('contact_map')) {
	initContactMap();
}


if(document.querySelector('#home_map')) {
	initHomeMap();
}

if(document.querySelector('#toggle_fac_info')) {
	initFacilityTop();
}

if(document.querySelector('#facility_caro')) {
	initFacCaro();
}

if(document.querySelector('#facility_map')) {
	initFacMap();
}

if(document.querySelector('#facility_jump_links')) {
	initJumpLinks();
}

if(document.querySelector('#facility_filters')) {
	initFilters();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}

//Accordion styling for Tips
if (document.getElementById('tips_items_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('tips_item_wrap')[0].classList.add('active');
	}, 500);
	const helpItems = document.querySelectorAll('.tips_item_wrap');
	document.getElementById('tips_items_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.nodeName === "H2") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
	});
}
