/* global google Velocity */

import { AutomatitCarousel } from './automatit_carousel.m.js';
import { getMapsHelper } from './util/mapsHelper.js';

const facilityTop = {
	mobileToggle: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_top'),
	mobileClick: function() {
		this.mobileToggle.switchAttribute('state', 'closed', 'open');
		this.info.switchAttribute('state', 'closed', 'open');
	},
	init: function() {
		this.mobileToggle.addEventListener('click', () => this.mobileClick());
	}
};

const facMap = {
	controls: document.querySelector('#facility_map_caro_controls'),
	controlButtons: document.querySelectorAll('#facility_map_caro_controls button'),
	theMap: {
		ele: document.querySelector('#facility_map'),
		instance: null
	},
	buttonClick: function(e) {
		const clickedButton = e.target.closest('button');

		if(clickedButton) {
			this.controlButtons.forEach(btn => btn.removeAttribute('active'));
			clickedButton.setAttribute('active', true);

			const activeButton = document.querySelector('#facility_map_caro_controls button[active]');

			this.theMap.ele.removeAttribute('show');

			if(activeButton && activeButton.getAttribute('tab') === 'map') {
				this.theMap.ele.setAttribute('show', true);
			}
		}
	},
	init: async function() {

		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '#facility_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span class="map_pin"><img src="/dist/images/icons/map_pin.svg" alt="" /></span>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(14);
			this.controls.addEventListener('click', e => this.buttonClick(e));
		});
	}
};

const facImgCaro = {
	init: function() {
		window.facImgCaro = AutomatitCarousel({
			element: document.querySelector('#facility_caro'),
			images: window.facCaroImgs,
			imagesAsBackgrounds: true,
			useChevrons: false,
		});
	}
};

const facilityJumpLinks = {
	jumpLinks: document.querySelector('#facility_jump_links'),
	linkClick: function(e) {
		const clickedButton = e.target.closest('a');

		if(clickedButton) {
			e.preventDefault();
			Velocity(document.querySelector(clickedButton.getAttribute('href')), 'scroll');
		}
	},
	init: function() {
		this.jumpLinks.addEventListener('click', e => this.linkClick(e));
	}
};

const facilityFilters = {
	filters: document.querySelectorAll('#facility_filters button'),
	resetFilter: document.querySelector('#facility_filters button[reset]'),
	sizeFilters: document.querySelectorAll('#facility_filters .sizes button'),
	typeFilters: document.querySelectorAll('#facility_filters .types button'),
	noUnitsMessage: document.querySelector('#no_units'),
	rows: document.querySelectorAll('.rates_row'),
	filterClick: function(e) {
		const clickedButton = e.target.closest('button');

		if(clickedButton) {
			if(clickedButton.hasAttribute('reset')) {
				this.filters.forEach(filter => filter.setAttribute('state', 'off'));
				this.resetFilter.setAttribute('state', 'on');
			} else {
				this.resetFilter.setAttribute('state', 'off');
				clickedButton.switchAttribute('state', 'on', 'off');
			}

			const activeSizes = Array.from([...this.sizeFilters].filter(filter => filter.getAttribute('state') === 'on').map(filter => filter.getAttribute('size')));
			const activeTypes = Array.from([...this.typeFilters].filter(filter => filter.getAttribute('state') === 'on').map(filter => filter.getAttribute('type')));

			let numUnits = 0;

			this.rows.forEach(row => {
				const matches = {
					size: false,
					type: false
				};
				row.setAttribute('hide', true);

				if(!activeSizes.length || activeSizes.includes(row.getAttribute('size')) || activeSizes.includes('all')) {
					matches.size = true;
				}
				if(!activeTypes.length || activeTypes.some(type => row.getAttribute('type').split('|').includes(type))) {
					matches.type = true;
				}

				if(matches.type && matches.size) {
					row.removeAttribute('hide');
					numUnits++;
				}
			});

			if(numUnits) {
				this.noUnitsMessage.style.display = 'none';
			} else {
				this.noUnitsMessage.style.display = 'block';
			}
		}
	},
	init: function() {
		document.querySelector('#facility_filters').addEventListener('click', e => this.filterClick(e));
	}
};

export const initFacilityTop = () => facilityTop.init();
export const initFacCaro = () => facImgCaro.init();
export const initFacMap = () => facMap.init();
export const initJumpLinks = () => facilityJumpLinks.init();
export const initFilters = () => facilityFilters.init();